<template>
  <v-container
    fluid
    class="pa-0 pa-md-3"
  >
    <v-card
      tile
      :loading="isLoading"
      flat
      class="mx-0 mx-md-4"
    >
      <v-card-title class="px-4 px-md-0">
        Подписка Strava
      </v-card-title>

      <v-card-text class="px-0">
        <div class="d-flex">
          <v-text-field
            v-model="callbackUrl"
            class="mr-2 shrink"
            label="Callback URL"
            placeholder="https://e72a3ce9.ngrok.io"
            outlined
            dense
          >
            <template #append>
              <div class="mt-1">
                /api/strava/subscription
              </div>
            </template>
          </v-text-field>

          <v-btn
            color="primary"
            class="mr-auto"
            :disabled="!callbackUrl || subscriptions.length > 0"
            @click="createSubscription"
          >
            Create
          </v-btn>

          <v-btn
            color="primary"
            @click="refreshSubscription"
          >
            Refresh
          </v-btn>
        </div>

        <v-data-table
          :headers="headers"
          :items="subscriptions"
          hide-default-footer
          :items-per-page="-1"
        >
          <template #item.actions="{ item }">
            <v-icon
              color="primary"
              small
              @click="deleteSubscription(item.id)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-card
      tile
      :loading="isLoading"
      flat
      class="mx-0 mx-md-4"
    >
      <v-card-title class="px-4 px-md-0">
        Загрузка тренировок
      </v-card-title>

      <v-card-text class="px-0">
        <div class="d-flex">
          <v-btn
            color="primary"
            @click="getActivities"
          >
            Загрузить тренировки
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import {
    activateFetchingStravaActivities,
    createStravaSubscription,
    deleteStravaSubscription,
    getStravaSubscriptions,
  } from '@/api/strava';

  export default {
    name: 'StravaSubscribes',

    mounted() {
      this.refreshSubscription();
    },

    data: () => ({
      isLoading: false,
      headers: [
        {
          text: 'ID',
          sortable: false,
          value: 'id',
        },
        {
          text: 'Callback URL',
          sortable: false,
          value: 'callback_url',
        },
        {
          text: 'Created',
          sortable: false,
          value: 'created_at',
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions',
        },
      ],
      callbackUrl: '',
      subscriptions: [],
    }),

    methods: {
      createSubscription() {
        this.isLoading = true;

        const data = {
          callback_url: this.callbackUrl,
        };

        createStravaSubscription(data)
          .then(() => {
            this.refreshSubscription();
          })
          .finally(() => {
            this.isLoading = false;
          });
      },

      refreshSubscription() {
        this.isLoading = true;

        getStravaSubscriptions()
          .then((response) => {
            this.subscriptions = response.data;
          })
          .finally(() => {
            this.isLoading = false;
          });
      },

      deleteSubscription(id) {
        this.isLoading = true;

        deleteStravaSubscription(id)
          .then(() => {
            this.refreshSubscription();
          })
          .finally(() => {
            this.isLoading = false;
          });
      },

      getActivities() {
        this.isLoading = true;

        const data = {
          user_id: 1,
          after: null,
          before: null,
          pages: 1,
          per_page: 25,
        };

        activateFetchingStravaActivities(data)
          .finally(() => {
            this.isLoading = false;
          });
      },
    },
  };
</script>
