import axios from 'axios';

export const getStravaSubscriptions = function () {
  const url = '/api/strava/subscription/refresh';

  return axios.get(url);
};

export const createStravaSubscription = function (data) {
  const url = '/api/strava/subscription/create';

  return axios.post(url, data);
};

export const deleteStravaSubscription = function (id) {
  const url = `/api/strava/subscription/delete/${id}`;

  return axios.delete(url);
};

export const activateFetchingStravaActivities = function (data) {
  const url = '/api/strava/athlete/activities';

  return axios.post(url, data);
};
